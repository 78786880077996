import React from "react"
import { graphql } from "gatsby"
import PostList from "../components/postlist"

class ArchiveIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = 'Archive';
    const posts = data.allMarkdownRemark.edges
    const introduction = (
      <span>
        <p>
          When I decided to do a complete visual update I also switched to English as my blogging language. This led to the problem where
          I should show old articles which are written in German. In order to avoid confusion I decided to open this section. Here you'll find
          links to all the articles I converted into my new post format.
        </p>
        <p>
          Older articles which didn't make it to the new format will be available at <a href="https://codingfreaksarchive.de" target="_blank" rel="noopener noreferrer">https://codingfreaksarchive.de</a>.
        </p>
      </span>
    );
    return (
      <PostList posts={posts} siteTitle={siteTitle} introduction={introduction}/>
    )
  }
}

export default ArchiveIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {postType: {eq: "Old"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            postType
            language
          }
        }
      }
    }
  }
`
